import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"login",attrs:{"fill-height":"","fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"xs":"12","sm":"6","md":"4"}},[(_vm.isLogIn)?_c(VCard,{staticClass:"elevation-12"},[_c(VCardText,[_vm._v("User already logged in.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.logout}},[_vm._v("log out")])],1)],1):_vm._e(),(!_vm.isLogIn)?_c(VCard,{staticClass:"elevation-12"},[_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.rules.email,"prepend-icon":"mdi-email","label":"Email","required":"","solo":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c(VTextField,{attrs:{"rules":_vm.rules.password,"prepend-icon":"mdi-lock","label":"Password","type":"password","required":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.clear}},[_vm._v("clear")]),_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.submit}},[_vm._v("submit")])],1),(_vm.isErrorLogin)?_c(VAlert,{attrs:{"type":"error"}}):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }